<template>
  <div
    class="flex align-items-center justify-content-center bg-cover h-screen"
    style="
      background-image: url('./img/loginBackground.jpg');
      background-size: cover;
      background-position: center center;
    "
  >
    <div
      class="p-6 shadow-6 text-center"
      style="
        border-radius: 12px;
        background-color: rgba(66, 60, 60, 0.4);
        color: rgba(255, 255, 255, 0.8);
        min-width: 40%;
      "
    >
      <!--div class="text-4xl font-medium mb-6">Welcome</div-->
      <img
        src="@/assets/companyLogo.svg"
        height="100"
        style="opacity: 0.3"
        alt="LLPGROUP"
      />
      <InputText
        v-model="loginDetails.user"
        type="text"
        class="appearance-none border-none p-3 w-full outline-none text-xl block mb-4 bg-white-alpha-10 text-white-alpha-60"
        style="border-radius: 30px"
      />
      <InputText
        v-model="loginDetails.password"
        type="password"
        class="appearance-none border-none p-3 w-full outline-none text-xl mb-4 bg-white-alpha-10 text-white-alpha-60"
        style="border-radius: 30px"
      />
      <Button
        type="button"
        class="justify-content-center appearance-none border-none p-3 w-full outline-none text-xl mb-4 font-medium w-12rem bg-white-alpha-30 hover:bg-white-alpha-40 active:bg-white-alpha-20 text-white-alpha-80 cursor-pointer transition-colors transition-duration-150"
        style="border-radius: 30px"
        @click="login"
        >Sign In</Button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";

const loginDetails = ref({
  user: "",
  password: "",
});

onMounted(() => {
  logout();
});

function login() {
  axios
    .post("/api/login", loginDetails.value)
    .then((res) => {
      console.log("LOGIN: ", res.data);
      store.commit("setUser", res.data);
      router.push("/main");
    })
    .catch((err) => {
      console.log(err);
    });
}

function logout() {
  axios
    .get("/api/logout")
    .then((res) => {
      console.log("LOGOUT: ", res.data);
    })
    .catch((err) => {
      console.log(err);
    });
}
</script>
