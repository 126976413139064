import "primevue/resources/themes/lara-light-purple/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core CSS
import "primeflex/primeflex.css"; //primeflex
import "primeicons/primeicons.css"; //icons

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import PrimeVue from "primevue/config";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Checkbox from "primevue/checkbox";
import Divider from "primevue/divider";
import Dialog from "primevue/dialog";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Ripple from "primevue/ripple";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import Calendar from "primevue/calendar";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Tooltip from "primevue/tooltip";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import dayjs from "dayjs";

export default createApp(App)
  .use(store)
  .use(router)
  .use(PrimeVue, { ripple: true })
  .use(ToastService)
  .component("Button", Button)
  .component("InputText", InputText)
  .component("Password", Password)
  .component("Checkbox", Checkbox)
  .component("Divider", Divider)
  .component("Dialog", Dialog)
  .component("Column", Column)
  .component("DataTable", DataTable)
  .component("Dropdown", Dropdown)
  .component("FileUpload", FileUpload)
  .component("Calendar", Calendar)
  .component("Toast", Toast)
  .component("ProgressBar", ProgressBar)
  .component("ProgressSpinner", ProgressSpinner)
  .directive("ripple", Ripple)
  .directive("tooltip", Tooltip)
  .provide("dayJS", dayjs)
  .mount("#app");
