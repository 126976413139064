<template>
  <div class="card">
    <h1>Settings - {{ company.name }} ({{ company.vat }})</h1>
    <Divider />
    <div class="grid">
      <div class="col">
        <div class="px-3 font-bold">
          <h2>ANAF token</h2>
          <Divider />
          <b>Token:</b> {{ company.anaftoken }}
          <br />
          <b>Token refresh:</b> {{ company.anaftokenref }}
          <br />
          <b>Token exp:</b>
          {{ dayJS(company.anaftokenexp * 1000).format("DD.MM.YYYY HH:mm") }}
          <Divider />
          <div class="p-fluid">
            <div class="p-field">
              <label for="clientID"> Client ID: </label>
              <InputText id="clientID" v-model="company.clientid" />
            </div>
            <div class="p-field">
              <label for="clientID"> Client Secret: </label>
              <InputText id="clientID" v-model="company.clientsecret" />
            </div>

            <div class="p-field">
              <Button
                label="Update client Settings"
                class="p-button-success my-2"
                @click="updateCompany"
              />
            </div>
            <div class="p-field">
              <Button
                label="Get ANAF token"
                class="p-button-success my-2"
                @click="getAnafToken"
              />
            </div>
            <div class="p-field">
              <Button
                label="TEST ANAF TOKEN"
                class="p-button-success my-2"
                @click="testAnafToken"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="text-center font-bold">
          <div class="px-3 font-bold">
            <h2>Upload token</h2>
            <Divider />
            <div class="p-fluid">
              <div class="p-field">
                <label for="basicDate"> Valid to: </label>
                <Calendar id="basicDate" v-model="basicAuthDate" />
              </div>
              <div class="p-field">
                <label for="bAuthUser"> User: </label>
                <span id="bAuthUser" class="p-input-icon-right">
                  <i class="pi pi-copy" @click="ctc(basicAuth.user)" />
                  <InputText v-model="basicAuth.user" />
                </span>
              </div>
              <div class="p-field">
                <label for="bAuthToken"> Token: </label>
                <span id="bAuthToken" class="p-input-icon-right">
                  <i class="pi pi-copy" @click="ctc(basicAuth.token)" />
                  <InputText v-model="basicAuth.token" />
                </span>
              </div>
              <div class="p-field">
                <label for="bAuthCurl"> CURL example: </label>
                <span id="bAuthCurl" class="p-input-icon-right">
                  <i class="pi pi-copy" @click="ctc(basicAuth.curl)" />
                  <InputText v-model="basicAuth.curl" />
                </span>
              </div>
              <Button
                label="Create basic Auth token"
                class="p-button-success my-2"
                @click="createBasicAuthToken"
              />
            </div>
          </div>
        </div>
        <div class="text-center font-bold">
          <div class="px-3 font-bold">
            <h2>systems@work integration</h2>
            <Divider />
            <div class="p-fluid">
              <div class="p-field">
                <label for="bAuthCurl"> s@w integration URL: </label>
                <span id="bAuthCurl" class="p-input-icon-right">
                  <InputText v-model="company.sawurl" />
                </span>
              </div>
              <div class="p-field">
                <label for="bAuthUser"> User: </label>
                <span id="bAuthUser" class="p-input-icon-right">
                  <InputText v-model="company.sawuser" />
                </span>
              </div>
              <div class="p-field">
                <label for="bAuthToken"> Password: </label>
                <span id="bAuthToken" class="p-input-icon-right">
                  <Password v-model="company.sawpass" />
                </span>
              </div>

              <Button
                label="Save settings"
                class="p-button-success my-2"
                @click="updateCompany"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DialogLoader :showLoader="showLoader" />
</template>

<script setup lang="ts">
import DialogLoader from "@/components/DialogLoader.vue";
import { onMounted, ref } from "vue";
import axios from "axios";
import store from "@/store";
import dayjs from "dayjs";

const dayJS = dayjs;

let basicDateDefault = new Date();
basicDateDefault.setFullYear(basicDateDefault.getFullYear() + 1);

const company = ref({
  name: "",
  vat: "",
  clientid: "",
  clientsecret: "",
  anaftoken: "",
  anaftokenref: "",
  anaftokenexp: 0,
  sawurl: "",
  sawuser: "",
  sawpass: "",
});
const basicAuthDate = ref(basicDateDefault);
const basicAuth = ref({ user: "", token: "", curl: "" });
const showLoader = ref(false);

onMounted(() => {
  getCompany();
});
/*
function anafLogin() {
  window.location.href = "/api/v1/oauth2/anaf";
}
*/
function getCompany() {
  showLoader.value = true;
  axios
    .get("api/v1/company")
    .then((response) => {
      console.log("COMP: ", response.data);
      company.value = response.data;
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    })
    .finally(() => {
      showLoader.value = false;
    });
}

function updateCompany() {
  axios
    .put("api/v1/companydetails", company.value)
    .then(() => {
      getCompany();
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    });
}

function testAnafToken() {
  axios
    .get("api/v1/anaftest")
    .then((response) => {
      store.commit("setToast", {
        sev: "success",
        sum: "Response",
        det: response.data.message,
      });
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    });
}

function createBasicAuthToken() {
  let currentDate = new Date();
  // calculate the difference between the two dates in minutes
  let difference = basicAuthDate.value.getTime() - currentDate.getTime();
  let minutesDifference = Math.floor(difference / 1000 / 60);
  console.log("Minutes: ", minutesDifference);

  axios
    .post("api/v1/tokenrequest", { validity: minutesDifference })
    .then((response) => {
      basicAuth.value = response.data;
      const baseUrl = window.location.origin;
      basicAuth.value.curl = `curl -u ${response.data.user}:${response.data.token} -X POST -H "Content-Type: text/xml" -d @yourfile.xml ${baseUrl}/apip/v1/invoice`;
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    });
}

const getAnafToken = () => {
  const link = document.createElement("a");
  link.href = `/api/v1/oauth2/anaf`;
  // link.setAttribute("download", props.linkInfo?.name as string);
  document.body.appendChild(link);
  link.click();
};

function ctc(text: string) {
  navigator.clipboard.writeText(text).then(
    function () {
      store.commit("setToast", {
        sev: "success",
        sum: "Copied to clipboard",
        det: text,
      });
    },
    function (err) {
      store.commit("setToast", {
        sev: "error",
        sum: "Error",
        det: err,
      });
    }
  );
}
</script>
