<template>
  <WindowAdmin v-if="store.state.user.role == 'admin'" />
  <WindowUser v-if="store.state.user.role == 'client'" />
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import router from "@/router";
import WindowAdmin from "@/components/WindowAdmin.vue";
import WindowUser from "@/components/WindowUser.vue";
import store from "@/store";
import axios from "axios";

onMounted(() => {
  axios
    .get("api/v1/currentuser")
    .then((response) => {
      store.commit("setUser", response.data);
    })
    .catch((error) => {
      console.log(error);
      router.push("/");
    });
});
</script>
