<template>
  <div class="grid justify-content-start align-items-start">
    <p class="col-2 text-2xl mt-3 mb-0">USERS:</p>
    <div class="col-7"></div>
    <div class="col-3">
      <Button
        label="Add User"
        icon="pi pi-plus"
        class="p-button-success mt-2"
        @click="addDialog = true"
      ></Button>
    </div>
  </div>
  <DataTable :value="users" tableStyle="min-width: 50rem">
    <Column field="RowKey" header="Name"></Column>
    <Column field="companyName" header="company"></Column>
    <Column field="role" header="Role"></Column>
  </DataTable>
  <Dialog
    v-model:visible="addDialog"
    header="Add Company"
    :modal="true"
    :style="{ width: '50vw' }"
  >
    <div class="px-3 my-4 font-bold">
      <div class="field grid">
        <label for="name" style="width: 100px">Name</label>
        <InputText id="name" v-model="user.RowKey" />
      </div>
      <div class="field grid">
        <label for="password" style="width: 100px">Password</label>
        <InputText id="password" type="password" v-model="user.password" />
      </div>
      <div class="field grid">
        <label for="company" style="width: 100px">Company</label>
        <Dropdown
          id="company"
          v-model="user.company"
          :options="companies"
          optionLabel="name"
          optionValue="RowKey"
          style="width: 250px"
        />
      </div>
      <div class="field grid">
        <label for="role" style="width: 100px">Admin</label>
        <Dropdown
          id="role"
          v-model="user.role"
          :options="roles"
          optionLabel="label"
          optionValue="value"
          style="width: 250px"
        />
      </div>
    </div>
    <Button
      label="Save"
      icon="pi pi-check"
      class="p-button-success"
      @click="saveUser"
    ></Button>
  </Dialog>
  <DialogLoader :showLoader="showLoader" />
</template>

<script setup lang="ts">
import DialogLoader from "@/components/DialogLoader.vue";
import { onMounted, ref } from "vue";
import axios from "axios";
import store from "@/store";

const users = ref([{ RowKey: "", company: "", companyName: "", admin: "" }]);
const companies = ref([]);
const addDialog = ref(false);
const user = ref({ RowKey: "", password: "", company: "", role: "" });
const roles = ref([
  { label: "Admin", value: "admin" },
  { label: "Client", value: "client" },
]);
const showLoader = ref(false);

onMounted(() => {
  getUsers();
});

function getUsers() {
  showLoader.value = true;
  axios
    .get("apiadm/v1/users")
    .then((response) => {
      users.value = response.data;
      getCompanies();
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    })
    .finally(() => {
      showLoader.value = false;
    });
}

function getCompanies() {
  axios
    .get("apiadm/v1/companies")
    .then((response) => {
      companies.value = response.data;
      let company = { RowKey: "", name: "" };
      for (const user of users.value) {
        for (company of companies.value) {
          if (user.company === company.RowKey) {
            user.companyName = company.name;
          }
        }
      }
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    });
}

function saveUser() {
  showLoader.value = true;
  axios
    .post("apiadm/v1/user", user.value)
    .then((response) => {
      console.log(response);
      getUsers();
      addDialog.value = false;
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    })
    .finally(() => {
      showLoader.value = false;
    });
}
</script>
