<template>
  <Dialog
    v-model:visible="show"
    modal
    :closable="false"
    :header="header"
    :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <p class="flex text-center text-2xl my-4">
      {{ message }}
    </p>
    <template #footer>
      <div class="flex">
        <Button
          label="YES"
          severity="danger"
          @click="$emit('delete', true)"
          class="flex-1 mr-4"
        />
        <Button
          label="NO"
          severity="success"
          @click="$emit('delete', false)"
          class="flex-1 ml-4"
        />
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed } from "vue";
const props = defineProps({
  showDelete: Boolean,
  header: String,
  message: String,
});
defineEmits(["delete"]);

const show = computed(() => {
  return props.showDelete ? true : false;
});
</script>
