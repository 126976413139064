<template>
  <router-view />
  <Toast />
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount } from "vue";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";

const store = useStore();
const toast = useToast();

store.state.win.w = window.innerWidth;
store.state.win.h = window.innerHeight;

store.watch(
  (state) => state.msgToast,
  (msgToast) => {
    if (msgToast.det !== "") {
      toast.add({
        severity: msgToast.sev,
        summary: msgToast.sum,
        detail: msgToast.det,
        life: 4000,
      });
    }
  }
);

// get query params on page load
onMounted(() => {
  // Nastav event listener pri zmene velkosti obrazovky
  window.addEventListener("resize", () => {
    store.state.win.w = window.innerWidth;
    store.state.win.h = window.innerHeight;
  });
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", () => {
    store.state.win.w = window.innerWidth;
    store.state.win.h = window.innerHeight;
  });
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
