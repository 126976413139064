import { createStore } from "vuex";
import router from "@/router";

export default createStore({
  state: {
    user: {
      name: "",
      company: "",
      role: "",
    },
    invoicesUploaded: false,
    msgToast: {
      sev: "", // severity
      sum: "", // summary
      det: "", // detail
    },
    // Current Window width and height
    win: {
      w: 0,
      h: 0,
    },
  },
  getters: {},
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setInvoicesUploaded(state, invoicesUploaded) {
      state.invoicesUploaded = invoicesUploaded;
    },
    setToast(state, toast) {
      state.msgToast = toast;
    },
  },
  actions: {
    catchErr(context, err) {
      console.log("catchErr", err);
      if (err.code === "ERR_NETWORK") {
        context.commit("setToast", {
          sev: "error",
          sum: "Network error",
          det: "Network communication error",
        });
        return;
      }
      if (typeof err.response.data === "string") {
        context.commit("setToast", {
          sev: "error",
          sum: "Server error",
          det: "Server communication error",
        });
        return;
      }
      // If unauthorized, redirect to login page
      if (err.response.status === 401) {
        context.commit("setToast", {
          sev: "error",
          sum: "Unauthorized",
          det: "Invalid login or session expired",
        });
        if (router.currentRoute.value.path !== "/") {
          router.push("/");
        }
        return;
      }
      context.commit("setToast", {
        sev: "error",
        sum: err.response.status,
        det: err.response.data.message,
      });
    },
  },
  modules: {},
});
