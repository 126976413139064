<template>
  <div class="grid justify-content-start align-items-start">
    <p class="col-2 text-2xl mt-3 mb-0">COMPANIES:</p>
    <div class="col-7"></div>
    <div class="col-3">
      <Button
        label="Add Company"
        icon="pi pi-plus"
        class="p-button-success mt-2"
        @click="addDialog = true"
      />
    </div>
  </div>
  <DataTable :value="companies" tableStyle="min-width: 50rem">
    <Column field="name" header="Name"></Column>
    <Column field="vat" header="Vat"></Column>
  </DataTable>
  <Dialog
    v-model:visible="addDialog"
    header="Add Company"
    :modal="true"
    :style="{ width: '50vw' }"
  >
    <div class="px-3 my-4 font-bold">
      <div class="field grid">
        <label for="name" class="col-fixed" style="width: 100px">Name</label>
        <InputText id="name" v-model="company.name" />
      </div>
      <div class="field grid">
        <label for="vat" class="col-fixed" style="width: 100px">Vat</label>
        <InputText id="vat" v-model="company.vat" />
      </div>
    </div>
    <Button
      label="Save"
      icon="pi pi-check"
      class="p-button-success"
      @click="saveCompanyDetails"
    ></Button>
  </Dialog>
  <DialogLoader :showLoader="showLoader" />
</template>

<script setup lang="ts">
import DialogLoader from "@/components/DialogLoader.vue";
import { onMounted, ref } from "vue";
import axios from "axios";
import store from "@/store";

const companies = ref([]);
const addDialog = ref(false);
const company = ref({ name: "", vat: "" });
const showLoader = ref(false);

onMounted(() => {
  getCompanies();
});

function getCompanies() {
  showLoader.value = true;
  axios
    .get("apiadm/v1/companies")
    .then((response) => {
      companies.value = response.data;
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    })
    .finally(() => {
      showLoader.value = false;
    });
}

function saveCompanyDetails() {
  showLoader.value = true;
  axios
    .post("apiadm/v1/company", company.value)
    .then(() => {
      getCompanies();
      addDialog.value = false;
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    })
    .finally(() => {
      showLoader.value = false;
    });
}
</script>
