<template>
  <DataTable
    :value="anafLog"
    style="width: 80vw; max-width: 80vw"
    scrollHeight="400px"
    scrollable
    class="overflow-x-hidden"
  >
    <Column
      field="dateFormated"
      header="date"
      sortable
      sortField="Timestamp"
      style="min-width: 200px"
    ></Column>
    <Column
      field="sev"
      header="Severity"
      sortable
      style="min-width: 100px"
    ></Column>
    <Column
      field="cat"
      header="Cathegory"
      sortable
      style="min-width: 200px"
    ></Column>
    <Column field="msg" header="msg" sortable>
      <template #body="slotProps">
        <div>
          <span style="width: 100px; overflow: hidden">
            {{ slotProps.data.msg }}
          </span>
        </div>
      </template>
    </Column>
  </DataTable>

  <Button
    v-if="
      props.invoice?.anafIncarcare === '' ||
      props.invoice?.anafIncarcare === undefined ||
      (props.invoice?.anafIncarcare !== '' &&
        props.invoice?.anafErr !== '' &&
        props.invoice?.anafErr !== undefined &&
        (props.invoice?.anafDescarcare === '' ||
          props.invoice?.anafDescarcare === undefined))
    "
    label="Delete invoice"
    icon="pi pi-times"
    class="p-button-rounded p-button-danger mt-3"
    @click="dialogDelete = true"
  ></Button>

  <DialogDelete
    :showDelete="dialogDelete"
    :header="`Delete Invoice?`"
    :message="`Are you sure you want to delete ${props.invoice?.name}?`"
    v-on:delete="(val) => (val ? deleteInvoice() : (dialogDelete = false))"
  />
  <DialogLoader :showLoader="showLoader" />
</template>

<script setup lang="ts">
import DialogDelete from "@/components/DialogDelete.vue";
import DialogLoader from "@/components/DialogLoader.vue";
import { onMounted, ref } from "vue";
import dayjs from "dayjs";
import axios from "axios";
import store from "@/store";

type tInvoice = {
  PartitionKey: string;
  RowKey: string;
  name: string;
  TimestampF: string;
  anafErr: string;
  anafState: string;
  anafIncarcare: string;
  anafDescarcare: string;
};

const props = defineProps({
  showAnafLog: Boolean,
  invoice: Object as () => tInvoice,
});

const emit = defineEmits(["closeAnafLog"]);
const anafLog = ref([]);
const dialogDelete = ref(false);
const showLoader = ref(false);

onMounted(() => {
  getAnafLog();
});

function getAnafLog() {
  axios
    .get(`api/v1/anaflog/${props.invoice?.RowKey}`)
    .then((response) => {
      response.data.forEach(
        (log: { Timestamp: string; dateFormated: string }) => {
          log.dateFormated = formatDate(log.Timestamp);
        }
      );
      anafLog.value = response.data;
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    });
}

function deleteInvoice() {
  showLoader.value = true;
  axios
    .delete(`api/v1/invoicepending/${props.invoice?.RowKey}`)
    .then(() => {
      showLoader.value = false;
      emit("closeAnafLog");
    })
    .catch((err) => {
      dialogDelete.value = false;
      store.dispatch("catchErr", err);
    })
    .finally(() => {
      showLoader.value = false;
    });
}

function formatDate(date: string) {
  return dayjs(date, "YYYYMMDDHHmmss").format("DD/MM/YYYY HH:mm");
}
</script>
