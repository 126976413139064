<template>
  <Button
    label="Upload"
    icon="pi pi-plus"
    class="p-button-success p-button-rounded mx-3"
    @click="uploadSelectFilesInput"
  />
  <Button
    label="Update all invoices"
    icon="pi pi-refresh"
    class="p-button-success p-button-rounded mx-3"
    @click="processAllInvoices"
  />

  <DataTable
    :value="invoices"
    :scrollHeight="`${store.state.win.h - 140}px`"
    :style="`height: ${store.state.win.h - 140}px; width: ${
      store.state.win.w - 230
    }px`"
    scrollable
  >
    <Column header="Status" style="max-width: 40px">
      <template #body="slotProps">
        <Button
          v-if="slotProps.data.anafErr"
          icon="pi pi-times"
          class="p-button-rounded p-button-danger"
          style="width: 40px; height: 40px"
          @click="(showAnafLog = true), (invoice = slotProps.data)"
          v-tooltip.bottom="slotProps.data.anafErr"
        />
        <Button
          v-else-if="
            slotProps.data.anafState || slotProps.data.anafIncarcare !== ''
          "
          icon="pi pi-stopwatch"
          class="p-button-rounded p-button-success"
          style="width: 40px; height: 40px"
          @click="(showAnafLog = true), (invoice = slotProps.data)"
          v-tooltip.bottom="slotProps.data.anafState"
        />
        <Button
          v-else
          icon="pi pi-info-circle"
          class="p-button-rounded p-button-warning"
          style="width: 40px; height: 40px"
          @click="(showAnafLog = true), (invoice = slotProps.data)"
          v-tooltip.bottom="'Show Anaf communication log'"
        />
      </template>
    </Column>
    <Column header="File" style="max-width: 80px">
      <template #body="slotProps">
        <Button
          icon="pi pi-download"
          class="p-button-rounded p-button-success"
          style="width: 40px; height: 40px"
          @click="downloadInvoice(slotProps.data)"
        />
      </template>
    </Column>
    <Column
      field="TimestampF"
      header="Updated"
      sortable
      sortField="Timestamp"
      style="min-width: 160px"
    ></Column>
    <Column
      field="invoiceID"
      header="Invoice ID"
      sortable
      style="
        min-width: 100px;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      "
    ></Column>
    <Column
      field="name"
      header="File name"
      sortable
      style="
        min-width: 100px;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      "
    ></Column>
    <Column
      field="issueDateF"
      header="Issued"
      sortable
      sortField="issueDate"
      style="min-width: 140px"
    ></Column>
  </DataTable>

  <Dialog
    class="w-30rem"
    v-model:visible="showProgress"
    modal
    :closable="false"
  >
    <b>{{ uploadState.fileName }}</b>
    <ProgressBar :value="uploadState.progress"></ProgressBar>
  </Dialog>

  <Dialog
    :header="`Invoice Status: ${invoice?.name} (${invoice?.invoiceID})`"
    class="w-60vw"
    v-model:visible="showAnafLog"
    modal
  >
    <DataTableAnafLog
      v-if="showAnafLog"
      :invoice="invoice"
      v-on:closeAnafLog="(showAnafLog = false), getInvoices()"
    />
  </Dialog>
  <DialogLoader :showLoader="showLoader" />
</template>

<script setup lang="ts">
import DataTableAnafLog from "@/components/DataTableAnafLog.vue";
import DialogLoader from "@/components/DialogLoader.vue";
import { onMounted, ref } from "vue";
import axios from "axios";
import store from "@/store";
import dayjs from "dayjs";

type tInvoice = {
  PartitionKey: string;
  RowKey: string;
  name: string;
  invoiceID: string;
  issueDateF: string;
  TimestampF: string;
  anafErr: string;
  anafState: string;
  anafIncarcare: string;
  anafDescarcare: string;
};

const invoices = ref<tInvoice[]>([]);
const invoice = ref<tInvoice>();
let chosenFiles = [] as File[];
const uploadState = ref({ progress: 0, fileName: "" });
const showProgress = ref(false);
const showAnafLog = ref(false);
const showLoader = ref(false);

onMounted(() => {
  getInvoices();
});

store.watch(
  (state) => state.invoicesUploaded,
  (uploaded) => {
    if (uploaded) {
      getInvoices();
      store.commit("setInvoicessUploaded", false);
    }
  }
);
function getInvoices() {
  showLoader.value = true;
  axios
    .get("api/v1/invoicespending")
    .then((response) => {
      response.data.forEach(
        (invoice: {
          Timestamp: string;
          TimestampF: string;
          issueDate: string;
          issueDateF: string;
        }) => {
          invoice.TimestampF = dayjs(invoice.Timestamp).format(
            "DD/MM/YYYY HH:mm"
          );
          invoice.issueDateF = dayjs(invoice.issueDate).format("DD/MM/YYYY");
        }
      );
      invoices.value = response.data;
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    })
    .finally(() => {
      showLoader.value = false;
    });
}

function downloadInvoice(invoice: { RowKey: string; name: string }) {
  axios
    .get(`api/v1/invoicepending/${invoice.RowKey}`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${invoice.name}`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    });
}

const uploadSelectFilesInput = () => {
  const input = document.createElement("input");
  input.type = "file";
  input.multiple = true;
  input.addEventListener("change", (e: Event) => {
    chosenFiles = Array.from((e.target as HTMLInputElement)?.files as FileList);
    filesUpload();
  });
  input.click();
};

function filesUpload() {
  const requests = chosenFiles.reduce((promiseChain, item) => {
    return promiseChain.then(
      () =>
        new Promise((resolve) => {
          showProgress.value = true;
          uploadState.value.fileName = item.name;
          axios
            .post(`/api/v1/invoicepending`, item, {
              headers: {
                "Content-Type": item.type,
                "Content-Disposition": `attachment; filename="${encodeURIComponent(
                  item.name
                )}"`,
              },
              onUploadProgress: function (progressEvent) {
                uploadState.value.progress = Math.round(
                  (progressEvent.loaded * 100) / (progressEvent.total || 1)
                );
              },
            })
            .then(() => {
              // this.uploadStep++;
              getInvoices();
              showProgress.value = false;
              uploadState.value = { progress: 0, fileName: "" };
              resolve();
            })
            .catch((err) => {
              showProgress.value = false;
              uploadState.value = { progress: 0, fileName: "" };
              if (err.code === "ERR_NETWORK") {
                store.commit("setToast", {
                  sev: "error",
                  sum: "Network error",
                  det: "File too large or network error",
                });
                return;
              }
              store.dispatch("catchErr", err);
            });
        })
    );
  }, Promise.resolve());
  requests.then(() => {
    store.commit("setToast", {
      sev: "success",
      sum: "Upload done",
      det: "All files succesfully uploaded",
    });
    // this.chosenFiles = [];
    // this.uploadLoader = false;
    // this.loadFiles();
  });
}

function processAllInvoices() {
  showLoader.value = true;
  axios
    .get("api/v1/invoicependingprocess")
    .then(() => {
      store.commit("setToast", {
        sev: "success",
        sum: "Invoices processed",
        det: "All invoices processed",
      });
      getInvoices();
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    })
    .finally(() => {
      showLoader.value = false;
    });
}
</script>
