<template>
  <div class="min-h-screen flex relative lg:static surface-ground">
    <div
      id="app-sidebar-2"
      class="bg-bluegray-800 h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none"
      style="width: 200px"
    >
      <div class="flex flex-column h-full">
        <img
          src="@/assets/companyLogo.svg"
          height="100"
          style="opacity: 0.3"
          alt="LLPGROUP"
        />
        <div class="overflow-y-auto mt-3">
          <ul class="list-none p-3 m-0">
            <li>
              <a
                class="flex align-items-center p-3 border-round text-bluegray-100"
              >
                <span class="font-medium">{{ store.state.user.name }}</span>
              </a>
            </li>
            <li @click="table = 'invoices'">
              <a
                v-ripple
                class="flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50 transition-duration-150 transition-colors p-ripple"
              >
                <i class="pi pi-book mr-2"></i>
                <span class="font-medium">Invoices</span>
              </a>
            </li>
            <li @click="table = 'company'">
              <a
                v-ripple
                class="flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50 transition-duration-150 transition-colors p-ripple"
              >
                <i class="pi pi-cog mr-2"></i>
                <span class="font-medium">Settings</span>
              </a>
            </li>
            <li @click="router.push('/')">
              <a
                v-ripple
                class="flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50 transition-duration-150 transition-colors p-ripple"
              >
                <i class="pi pi-sign-out mr-2"></i>
                <span class="font-medium">logOut</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="min-h-screen flex flex-column relative flex-auto">
      <div class="p-1 flex flex-column flex-auto">
        <div
          class="border-2 border-dashed surface-border border-round surface-section flex-auto"
        >
          <UserSettings v-if="table === 'company'" />
          <UserInvoices v-if="table === 'invoices'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import router from "@/router";
import UserSettings from "@/components/UserSettings.vue";
import UserInvoices from "@/components/UserInvoices.vue";
import store from "@/store";

const table = ref("invoices");
</script>
