<template>
  <Button
    label="Refresh"
    icon="pi pi-refresh"
    class="p-button-success p-button-rounded mx-3"
    @click="getInvoices"
  />
  <DataTable
    :value="invoices"
    :scrollHeight="`${store.state.win.h - 140}px`"
    :style="`height: ${store.state.win.h - 140}px; width: ${
      store.state.win.w - 230
    }px`"
    scrollable
  >
    <Column header="State" style="max-width: 50px">
      <template #body="slotProps">
        <Button
          v-if="slotProps.data.anafState == 'nok'"
          icon="pi pi-times"
          class="p-button-rounded p-button-danger"
          style="width: 40px; height: 40px"
          @click="(showAnafLog = true), (invoice = slotProps.data)"
          v-tooltip.bottom="
            `Not accepted by ANAF.\n Detailed description in Signed file`
          "
        />
        <Button
          v-if="slotProps.data.anafState == 'ok'"
          icon="pi pi-check"
          class="p-button-rounded p-button-success"
          style="width: 40px; height: 40px"
          v-tooltip.bottom="`Succesfully confirmed by ANAF`"
        />
      </template>
    </Column>
    <Column header="File" style="max-width: 50px">
      <template #body="slotProps">
        <Button
          icon="pi pi-download"
          class="p-button-rounded p-button-success"
          @click="downloadInvoice(slotProps.data)"
          style="width: 40px; height: 40px"
          v-tooltip.bottom="`Download original file`"
        />
      </template>
    </Column>
    <Column header="Signed" style="max-width: 50px">
      <template #body="slotProps">
        <Button
          icon="pi pi-download"
          class="p-button-rounded p-button-success"
          @click="downloadInvoiceSigned(slotProps.data)"
          style="width: 40px; height: 40px"
          v-tooltip.bottom="`Download file signed by ANAF`"
        />
      </template>
    </Column>
    <!--Column header="PDF" style="max-width: 50px">
      <template #body="slotProps">
        <Button
          icon="pi pi-download"
          class="p-button-rounded p-button-success"
          @click="downloadPDFInvoice(slotProps.data)"
          style="width: 40px; height: 40px"
          v-tooltip.bottom="`Download file signed by ANAF`"
        />
      </template>
    </Column-->
    <Column
      field="TimestampF"
      header="Updated"
      sortable
      sortField="Timestamp"
      style="min-width: 160px"
    ></Column>
    <Column
      field="invoiceID"
      header="Invoice ID"
      sortable
      style="
        min-width: 100px;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      "
    ></Column>
    <Column
      field="name"
      header="File name"
      sortable
      style="
        min-width: 100px;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      "
    ></Column>
    <Column
      field="issueDateF"
      header="Issue Date"
      sortable
      sortField="issueDate"
      style="min-width: 140px"
    ></Column>
  </DataTable>
  <Dialog
    :header="`Invoice Status: ${invoice?.name} (${invoice?.invoiceID})`"
    class="w-60vw"
    v-model:visible="showAnafLog"
    modal
  >
    <DataTableAnafLog
      v-if="showAnafLog"
      :invoice="invoice"
      v-on:closeAnafLog="(showAnafLog = false), getInvoices()"
    />
  </Dialog>
</template>

<script setup lang="ts">
import DataTableAnafLog from "@/components/DataTableAnafLog.vue";
import { onMounted, ref } from "vue";
import axios from "axios";
import store from "@/store";
import dayjs from "dayjs";

type tInvoice = {
  PartitionKey: string;
  RowKey: string;
  name: string;
  invoiceID: string;
  issueDateF: string;
  TimestampF: string;
  anafErr: string;
  anafState: string;
  anafIncarcare: string;
  anafDescarcare: string;
};

const invoices = ref<tInvoice[]>([]);
const invoice = ref<tInvoice>();
const showAnafLog = ref(false);

onMounted(() => {
  getInvoices();
});

store.watch(
  (state) => state.invoicesUploaded,
  (uploaded) => {
    if (uploaded) {
      getInvoices();
      store.commit("setInvoicesUploaded", false);
    }
  }
);
function getInvoices() {
  axios
    .get("api/v1/invoices")
    .then((response) => {
      response.data.forEach(
        (invoice: {
          Timestamp: string;
          TimestampF: string;
          issueDate: string;
          issueDateF: string;
        }) => {
          invoice.TimestampF = dayjs(invoice.Timestamp).format(
            "DD/MM/YYYY HH:mm"
          );
          invoice.issueDateF = dayjs(invoice.issueDate).format("DD/MM/YYYY");
        }
      );
      invoices.value = response.data;
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    });
}

function downloadInvoice(invoice: { RowKey: string; name: string }) {
  axios
    .get(`api/v1/invoice/${invoice.RowKey}`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${invoice.name}`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    });
}
/*
function downloadPDFInvoice(invoice: { RowKey: string; name: string }) {
  axios
    .get(`api/v1/invoicepdf/${invoice.RowKey}`, {
      // responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice.pdf`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    });
}
*/
function downloadInvoiceSigned(invoice: { RowKey: string; name: string }) {
  axios
    .get(`api/v1/invoicesigned/${invoice.RowKey}`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${invoice.name}.zip`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    });
}
</script>
