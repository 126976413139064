<template>
  <Button
    label="Refresh"
    icon="pi pi-refresh"
    class="p-button-success p-button-rounded mx-3"
    @click="getInvoices"
  />

  <DataTable
    :value="messageList.mesaje"
    :scrollHeight="`${store.state.win.h - 140}px`"
    :style="`height: ${store.state.win.h - 140}px; width: ${
      store.state.win.w - 230
    }px`"
    scrollable
  >
    <Column field="detalii" header="Detail" sortable></Column>
    <Column
      field="date_formated"
      header="Created"
      sortable
      sortField="data_creare"
    ></Column>
    <Column header="Download">
      <template #body="slotProps">
        <Button
          icon="pi pi-download"
          class="p-button-rounded p-button-success"
          style="width: 30px; height: 30px"
          @click="downloadInvoice(slotProps.data.id)"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import axios from "axios";
import store from "@/store";
import dayjs from "dayjs";

const messageList = ref({ mesaje: [], serial: "", cui: "", titlu: "" });

onMounted(() => {
  getInvoices();
});

store.watch(
  (state) => state.invoicesUploaded,
  (uploaded) => {
    if (uploaded) {
      getInvoices();
      store.commit("setInvoicessUploaded", false);
    }
  }
);
function getInvoices() {
  axios
    .get("api/v1/anafgetlist")
    .then((response) => {
      response.data.mesaje.forEach(
        (mesaje: { data_creare: string; date_formated: string }) => {
          mesaje.date_formated = formatDate(mesaje.data_creare);
        }
      );
      messageList.value = response.data;
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    });
}

function downloadInvoice(id: string) {
  axios
    .get(`api/v1/anafgetbyreference/${id}`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${id}.zip`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      store.dispatch("catchErr", err);
    });
}

function formatDate(date: string) {
  return dayjs(date, "YYYYMMDDHHmmss").format("DD/MM/YYYY HH:mm");
}
</script>
