<template>
  <Dialog v-model:visible="show" modal :closable="false">
    <template #container>
      <ProgressSpinner
        style="width: 400px; height: 400px; overflow: hidden"
        strokeWidth="4"
        class="opacity-80"
      />
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed } from "vue";
const props = defineProps({
  showLoader: Boolean,
});
const show = computed(() => {
  return props.showLoader ? true : false;
});
</script>
